.card-container {
  display: flex;
  flex-wrap: wrap;
  flex:6;
  // align-items: center;
  // justify-content: center;

  .card {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .card-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
      color: rgb(245, 245, 222);
      text-align: center;
    }
    .card-button {
      width: 50%;
      margin: auto;
      font-size: 18px;
      background-color: rgb(252, 252, 124);
      font-weight: bold;
      margin-bottom: 5px;
      color: rgba(54, 54, 54, 0.555);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      text-align: center;
      align-items: center;
      justify-content: center;

    }

    .card-content {
      /* Add your styles for the card content here */
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(245, 245, 222);
    }

    &.available {
      background-color: #51910c;
    }
    &.occupied {
      background-color: yellow;
     color:black;
    }
  }
}
