.navbar{
  height: 50px;
  border:1px solid lightgray;
  display: flex;
  align-items: center;
  font-size: 14px;
  color:#555;
  .wrapper{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search{margin: 2px;
      display: flex;
      align-items: center;
      border:1px solid #828283;
      background-color: transparent;

      &::placeholder{
        font-size: 12px;
      }
     
      input{
        border: none;
        outline: none

      }
    }
    .items{
      display: flex;
      align-items: center;
      .item{
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        .icon{
          font-size: 22px;
        }
        .avatar{
          width: 30px;
          height: 30px;
          border-radius:50% ;
        }
        .counter{
          width: 15px;
          height: 15px;
          background: red;
          border-radius: 50%;
          color:white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top:-5px;
          right:-5px;
      
}
      }

    }
  }


}