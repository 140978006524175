.table{
  display: flex;
  .tableContainer{

    flex:6;
    .widgets, .bills{
      display: flex;
      padding: 5px;

      gap: 5px;
    }
    .listContainer{
     flex-wrap: wrap;
      padding: 20px;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      margin: 20px;
      .listTitle{
        font-weight: 500;
        color:gray;
        margin-bottom: 15px;
      }
    }
  
  }
}