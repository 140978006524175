/* addprinter.scss */

.addprinter {
  display: flex;
  width: 100%;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .right {
        flex: 2;
        padding: 10px;

        /* Messages styles */
        .success-message {
          color: green;
          margin-bottom: 10px;
        }

        .error-message {
          color: red;
          margin-bottom: 10px;
        }
        /* End Messages styles */

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .formInput {
            width: 48%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input[type="checkbox"] {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              width: 20px;
              height: 20px;
              border: 1px solid #ccc;
              border-radius: 3px;
              margin-right: 8px;
              vertical-align: middle;
              cursor: pointer;

              &:checked {
                background-color: #007bff;
                border-color: #007bff;
                color: #fff;
              }
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: #e10564;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
