/* addtable.scss */

.addqueue {
  display: flex;
  width: 100%;

  .newContainer {
    flex: 6;

    .top {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      margin: 20px;
      display: flex;
      flex-direction: column; /* Align items in column */
      align-items: center; /* Center items horizontally */

      h1 {
        color: rgb(247, 184, 184);
        font-size: 20px;
        margin-bottom: 10px; /* Add space below heading */
      }

      p {
        color: #028050;
        font-size: 24px; /* Increase font size */
        margin-bottom: 20px; /* Add space below total calculated value */
      }
    }
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }
p{
  align-items: center;
}
      .right {
        flex: 2;
        padding: 10px;

        /* Messages styles */
        .success-message {
          color: green;
          margin-bottom: 10px;
        }

        .error-message {
          color: red;
          margin-bottom: 10px;
        }
        /* End Messages styles */

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .formInput {
            width: 48%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;

              &.available-status {
                background-color: green;
              }
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: #028050;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
