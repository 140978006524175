.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  border-radius: 10px;
  height: 120px;
  width: calc(33.33% - 16px); // Adjust the width based on your requirements
  margin: 10px; // Adjust the margin based on your requirements

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 15px;
      color: gray;
    }

    .counter {
      font-size: 28px;
      font-weight: 300;
      
      .status {
        align-items: center;
        font-size: 14px;
        color:red;
  
        &.available {
          color: green;
        }
  
        &.unavailable {
          color: red;
        }
  
        &.reserved {
          color: rgb(237, 133, 14);
        }
      }
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }


    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: green;
      }

      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 30px;
      padding: 5px;
      background-color: rgb(182, 3, 66);
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}
