.chart{
  flex:4;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
padding: 10px;
  color:gray;

  .title{
    margin-bottom: 20px;
  }
  .chartGrid{
    stroke: rgba(211, 211, 211, 0.482);
  }
}