// Existing styles
.css-1ex1afd-MuiTableCell-root {
  /* Keep existing styles */
  
  // Override padding
  padding: 10px ;
}
$modalStyle: (
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  // border: '1px solid #000',
  boxShadow: 24,
  p: 4,
);
.edit-icon {
  color: #e10564; 
}

.status-icon {
  color: #e10564; 
}

.delete-icon {
  color: #e10564; 
}
// Styles for the modal
.producttable {
  .MuiModal-root {
    &.MuiDialog-root {
      .MuiPaper-root {
        @each $property, $value in $modalStyle {
          #{$property}: #{$value};
        }
        // Additional styles specific to the modal box can be added here
        width: 50%;
        max-width: 600px;
        margin: 0 auto;
        padding: 10px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }

        .formInput {
          width: 100%;

          label {
            display: block;
            margin-bottom: 10px;

            input,
            textarea,
            select {
              width: 100%;
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 4px;
              margin-top: 5px;
            }
          }
        }

        button {
          width: 100%;
          padding: 10px;
          border: none;
          background-color: #e10564;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
        }
      } // Closing curly brace for .MuiPaper-root
    }
  }

  // Rest of the styles...

  .inStock {
    padding: 5px;
    border-radius: 5px;

    &.in-stock {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }

    &.out-of-stock {
      color: rgb(190, 80, 6);
      background-color: rgba(245, 161, 6, 0.466);
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .formInput {
      width: 48%;

      label {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        padding: 5px;
        border: none;
        border-bottom: 1px solid gray;
      }
    }

    button {
      width: 150px;
      padding: 10px;
      border: none;
      background-color: #e10564;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-top: 10px;
    }
  }

  // Add or update styles for other components if needed
}
