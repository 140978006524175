.login{
  display: flex;

 .right {
  flex: 1;
  background-image: url(../../foodbg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;



  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e10564;
    /* This ensures the content takes up the full height */

    img {
      width: 40%;
    }

    h2 {
      margin-top: 10px; /* Adjust as needed */
    }
  }

  .bottom {
    background-image: image(""); // Not sure what you want here
    height: 60vh;
  }
}

  .left {
    display: flex;
    flex: 1;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color:rgba(233, 150, 122, 0.063);
.wrapper{
  display: flex;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  padding: 50px;
  background-color:rgba(250, 250, 250, 0.261);

  form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
      text-align: center;
      align-items: center;

      .formInput {
          width: 100%;
          text-align: center;

          label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                  cursor: pointer;
              }
          }

          input {
              width: 100%;
              padding: 10px;
              border: none;
              border: 1px solid #e10564;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
              box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
              border-radius: 10px;
}
      }

      button {
      
          width: 120px;
          padding: 10px;
          border: none;
          background-color: #e10564;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
      }
  }
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
}
}

}

